<template>
  <v-container class="pa-0">
    <v-row class="no-gutters elevation-0">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0" v-if="!searchResult" v-cloak>
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <label>
                <span>{{ $t("dmvProcessing.title") }}</span>
              </label>
            </v-card-title>
            <v-card-text
              class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"
            >
              <div class="pa-10">
                <template>
                  <v-form>
                    <span class="v-card__title">
                      {{ $t("dmvProcessing.selectNote") }}
                    </span>
                    <v-card-title class="pa-0 pl-3 pr-3 pb-10">
                      <v-radio-group
                        v-model="processingType"
                        row
                        class="custom-pdm"
                        @change="initializeFields()"
                      >
                        <v-radio
                          :label="$t('dmvProcessing.insideRedlineInventory')"
                          value="inside_redline_inventory"
                          class="pb-4"
                        ></v-radio>
                        <v-radio
                          :label="$t('dmvProcessing.outsideRedlineInventory')"
                          value="outside_redline_inventory"
                          class="pb-4"
                        ></v-radio>
                      </v-radio-group>
                    </v-card-title>
                  </v-form>
                </template>
              </div>
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
      <v-col
        cols="auto"
        class="flex-grow-1 flex-shrink-0"
        v-if="searchResult && !createMode" v-cloak
      >
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height"
            >
            <v-container fluid class="pa-0">
              <template>
                <v-toolbar color="white" elevation="0" class="bt-top less-pd">
                  <v-btn
                    small
                    @click="back"
                    light
                    min-height="40"
                    class="post-reply-btn green darken-4 green-gradient white--text create-post-btn mr-4 pr-4"
                    elevation="0"
                  >
                    <v-icon size="25" class="white--text text--darken-4 mr-4"
                      >mdi-arrow-left-bold
                    </v-icon>
                    {{ $t("dmvProcessing.back") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <label>
                    <span
                      ><h2 class="green--text text--darken-4">
                        {{ $t("dmvProcessing.title") }}
                      </h2></span
                    >
                    <h4
                      class="green--text text--darken-4"
                      v-if="processingType !== 'inside_redline_inventory'"
                    >
                      {{ $t("dmvProcessing.outsideRedlineInventory") }}
                    </h4>
                    <h4
                      class="green--text text--darken-4"
                      v-if="processingType === 'inside_redline_inventory'"
                    >
                      {{ $t("dmvProcessing.insideRedlineInventory") }}
                    </h4>
                  </label>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="processingType === 'outside_redline_inventory'"
                    height="40"
                    small
                    color="green darkan-4 green-gradient white--text create-post-btn"
                    class="text-capitalize"
                    @click="createMode=true"
                  ><v-icon>mdi-plus</v-icon>{{ $t("dmvProcessing.addDmv") }}
                  </v-btn>
                </v-toolbar>
              </template>
              <div
                class="custom-card"
                v-if="processingType === 'inside_redline_inventory'"
              >
                <div class="custom-box">
                  <template>
                    <v-data-table
                      :header-props="{ sortIcon: null }"
                      height="calc(100vh - 366px)"
                      :headers="headers"
                      :items="carProfiles"
                      :disable-sort="true"
                      fixed-header
                      class="color-green booklog-table-wrapper"
                      :loading="loading"
                      :page.sync="page"
                      :items-per-page="itemsPerPage"
                      @page-count="pageCount = $event"
                      hide-default-footer
                    >
                      <template v-slot:item.created_at="{ item }">
                        <span class="datebreak">{{
                            item.created_at | DateTimezoneFilter
                          }}</span>
                      </template>
                      <template v-slot:item.makeModelYear="{ item }">
                        <span class="text-pre-wrap">
                          {{ item.make + "/" + item.model + "/" + item.year }}
                        </span>
                      </template>
                      <template v-slot:item.dmvProcessed="{ item }">
                        <span class="text-pre-wrap">
                          {{ item.has_inventory_dmv_processing }}
                        </span>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <a class="" @click="setDmv(item)">
                          <v-icon v-if="item.has_inventory_dmv_processing === 'Yes'">fas fa-edit</v-icon>
                          <v-icon v-if="item.has_inventory_dmv_processing === 'No'">fas fa-plus</v-icon>
                        </a>
                        <a
                          class="mr-2"
                          v-if="item.has_inventory_dmv_processing === 'Yes'"
                          @click="
                            openConfirmModal(item.inventory_dmv_processing)
                          "
                        >
                          <v-icon>fas fa-print</v-icon> </a
                        >&nbsp;
                      </template>
                    </v-data-table>
                    <div class="text-center">
                      <v-pagination
                        v-model="page"
                        :length="metaInfo.last_page"
                        @input="getDMV"
                      >
                      </v-pagination>
                    </div>
                  </template>
                </div>
              </div>
              <div
                class="custom-card"
                v-if="processingType === 'outside_redline_inventory'"
              >
                <div class="custom-box">
                  <template>
                    <v-data-table
                      :header-props="{ sortIcon: null }"
                      height="calc(100vh - 366px)"
                      :headers="dmvHeaders"
                      :items="dmvProcessing"
                      :disable-sort="true"
                      fixed-header
                      class="color-green booklog-table-wrapper"
                      :loading="loading"
                      :page.sync="page"
                      :items-per-page="itemsPerPage"
                      @page-count="pageCount = $event"
                      hide-default-footer
                    >
                      <template v-slot:item.created_at="{ item }">
                        <span class="datebreak">{{
                            item.created_at | DateTimezoneFilter
                          }}</span>
                      </template>
                      <template v-slot:item.makeModelYear="{ item }">
                        <span class="text-pre-wrap">
                          {{ item.make + "/" + item.year_model }}
                        </span>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <a class="mr-2" @click="setOutsideDmv(item)">
                          <v-icon>fas fa-edit</v-icon>
                        </a>
                        <a
                          class="mr-2"
                          @click="
                            openConfirmModal(item)
                          "
                        >
                          <v-icon>fas fa-print</v-icon> </a
                        >&nbsp;
                      </template>
                    </v-data-table>
                    <div class="text-center">
                      <v-pagination
                        v-model="page"
                        :length="metaInfo.last_page"
                        @input="getDMV"
                      >
                      </v-pagination>
                    </div>
                  </template>
                </div>
              </div>
            </v-container>
          </v-card>
          <v-dialog
            hide-overlay
            v-model="confirmation"
            persistent
            max-width="380"
            :attach="true"
          >
            <v-card>
              <v-card-text>
                <v-card-title class="headline">
                  <strong>{{ $t("dmvProcessing.printConfirmationText") }}</strong>
                </v-card-title>
                <div id="dmv-main" style="background: url(../assets/bg.jpg) no-repeat scroll 0 0; width: 2550px; height: 3300px; padding: 50px 150px;" hidden>
                  <div style="height: 510px;"></div>
                  <div style="display: inline-flex !important; width: 100%;">
                    <div style="float: left !important; padding-right: 5px; width: 74%;">
                      <div style="display: inline-flex !important; width: 100%;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 35px; width: 210px;">
                            {{ getMomentProperty(selectedDmv, 'period_ends_at', 'MM') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 35px; width: 210px;">
                            {{ getMomentProperty(selectedDmv, 'period_ends_at', 'DD') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 35px; width: 210px;">
                            {{ getMomentProperty(selectedDmv, 'period_ends_at', 'yyyy') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin-right: 70px; width: 210px;">
                            {{ getProperty(selectedDmv, 'state_of') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; width: 460px;">
                            {{ getProperty(selectedDmv, 'license_no') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin-left: 410px; margin-top: 20px; width: 1100px;">
                            {{ getProperty(selectedDmv, 'vin') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%;">
                        <div style="float: left !important; margin-top: 70px; height: 50px;"></div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 45px;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; width: 510px;">
                            {{ getProperty(selectedDmv, 'make') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 20px 0 105px; width: 400px;">
                            {{ getProperty(selectedDmv, 'body_type') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 35px; width: 210px;">
                            {{ getProperty(selectedDmv, 'year_model') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 50px;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 34px; width: 210px;">
                            {{ getMomentProperty(selectedDmv, 'possession_acquired_at', 'MM') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 34px; width: 210px;">
                            {{ getMomentProperty(selectedDmv, 'possession_acquired_at', 'DD') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 34px; width: 210px;">
                            {{ getMomentProperty(selectedDmv, 'possession_acquired_at', 'yyyy') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 34px; width: 210px;">
                            {{ getMomentProperty(selectedDmv, 'purchased_at', 'MM') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 34px; width: 210px;">
                            {{ getMomentProperty(selectedDmv, 'purchased_at', 'DD') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 34px; width: 210px;">
                            {{ getMomentProperty(selectedDmv, 'purchased_at', 'yyyy') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin-left: 210px; margin-top: 20px; width: 1400px;">
                            {{ getProperty(selectedDmv, 'sellers_name') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 50px;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin-left: 110px; margin-top: 20px; width: 1500px;">
                            {{ getProperty(selectedDmv, 'address') + " " + getProperty(selectedDmv, 'apt_no') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 45px;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 345px 0 35px; width: 510px;">
                            {{ getProperty(selectedDmv, 'city') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 20px 0 105px; width: 320px;">
                            {{ getProperty(selectedDmv, 'state') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 35px; width: 210px;">
                            {{ getProperty(selectedDmv, 'zip_code') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 120px;">
                        <input
                          type="checkbox"
                          name="check-8"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px; margin-left: -2px;"
                          v-if="getProperty(selectedDmv, 'removed_license_plate') == true"
                          checked>
                        <input
                          type="checkbox"
                          name="check-8"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px; margin-left: -2px;"
                          v-if="getProperty(selectedDmv, 'removed_license_plate') == false">
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 15px;">
                        <div style="float: left !important; margin-left: 952px;">
                          <input
                            type="checkbox"
                            name="check-4"
                            style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                            v-if="getProperty(selectedDmv, 'number_of_plates_removed') == 1"
                            checked>
                          <input
                            type="checkbox"
                            name="check-4"
                            style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                            v-if="getProperty(selectedDmv, 'number_of_plates_removed') != 1">
                        </div>
                        <div style="float: left !important; margin-left: 110px;">
                          <input
                            type="checkbox"
                            name="check-5"
                            style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                            v-if="getProperty(selectedDmv, 'number_of_plates_removed') == 2"
                            checked>
                          <input
                            type="checkbox"
                            name="check-5"
                            style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                            v-if="getProperty(selectedDmv, 'number_of_plates_removed') != 2">
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 10px;">
                        <input
                          type="checkbox"
                          name="check-10"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px; margin-left: -2px;"
                          v-if="getProperty(selectedDmv, 'possession_without_license_plates') == true"
                          checked>
                        <input
                          type="checkbox"
                          name="check-10"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px; margin-left: -2px;"
                          v-if="getProperty(selectedDmv, 'possession_without_license_plates') == false">
                      </div>
                    </div>
                    <div style="float: left !important; padding-left: 4px; width: 26%;">
                      <div style="display: inline-flex !important; width: 100%; margin-top: 95px;">
                        <input
                          type="checkbox"
                          name="check-1"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'certificate_of_title') == true"
                          checked>
                        <input
                          type="checkbox"
                          name="check-1"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'certificate_of_title') == false">
                      </div>
                      <div style="display: inline-flex !important; width: 100%;">
                        <input
                          type="checkbox"
                          name="check-2"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'registration_card') == true"
                          checked>
                        <input
                          type="checkbox"
                          name="check-2"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'registration_card') == false">
                      </div>
                      <div style="display: inline-flex !important; width: 100%;">
                        <input
                          type="checkbox"
                          name="check-3"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'validation') == true"
                          checked>
                        <input
                          type="checkbox"
                          name="check-3"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'validation') == false">
                      </div>
                      <div style="display: inline-flex !important; width: 100%;">
                        <div style="float: left !important; margin-right: 268px;">
                          <input
                            type="checkbox"
                            name="check-4"
                            style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                            v-if="getProperty(selectedDmv, 'license_plates') != null"
                            checked>
                          <input
                            type="checkbox"
                            name="check-4"
                            style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                            v-if="getProperty(selectedDmv, 'license_plates') == null">
                        </div>
                        <div style="float: left !important; margin-right: 93px;">
                          <input
                            type="checkbox"
                            name="check-5"
                            style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                            v-if="getProperty(selectedDmv, 'license_plates') == 1"
                            checked>
                          <input
                            type="checkbox"
                            name="check-5"
                            style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                            v-if="getProperty(selectedDmv, 'license_plates') != 1">
                        </div>
                        <div style="float: left !important;">
                          <input
                            type="checkbox"
                            name="check-6"
                            style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                            v-if="getProperty(selectedDmv, 'license_plates') == 2"
                            checked>
                          <input
                            type="checkbox"
                            name="check-6"
                            style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                            v-if="getProperty(selectedDmv, 'license_plates') != 2">
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%;">
                        <input
                          type="checkbox"
                          name="check-7"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'lien_sale_documents') == true"
                          checked>
                        <input
                          type="checkbox"
                          name="check-7"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'lien_sale_documents') == false">
                      </div>
                      <div style="display: inline-flex !important; width: 100%;">
                        <input
                          type="checkbox"
                          name="check-8"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'salvage_certificate') == true"
                          checked>
                        <input
                          type="checkbox"
                          name="check-8"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'salvage_certificate') == false">
                      </div>
                      <div style="display: inline-flex !important; width: 100%;">
                        <input
                          type="checkbox"
                          name="check-9"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'non_repairable_vehicle_certificate') == true"
                          checked>
                        <input
                          type="checkbox"
                          name="check-9"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'non_repairable_vehicle_certificate') == false">
                      </div>
                      <div style="display: inline-flex !important; width: 100%;">
                        <input
                          type="checkbox"
                          name="check-10"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'bill_of_sale') == true"
                          checked>
                        <input
                          type="checkbox"
                          name="check-10"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'bill_of_sale') == false">
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 63px;">
                        <input
                          type="checkbox"
                          name="check-7"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'complete_vehicle') == true"
                          checked>
                        <input
                          type="checkbox"
                          name="check-7"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'complete_vehicle') == false">
                      </div>
                      <div style="display: inline-flex !important; width: 100%;">
                        <input
                          type="checkbox"
                          name="check-8"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'frame_only') == true"
                          checked>
                        <input
                          type="checkbox"
                          name="check-8"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'frame_only') == false">
                      </div>
                      <div style="display: inline-flex !important; width: 100%;">
                        <input
                          type="checkbox"
                          name="check-9"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'body_only') == true"
                          checked>
                        <input
                          type="checkbox"
                          name="check-9"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'body_only') == false">
                      </div>
                      <div style="display: inline-flex !important; width: 100%;">
                        <input
                          type="checkbox"
                          name="check-10"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'partially_dismantled') == true"
                          checked>
                        <input
                          type="checkbox"
                          name="check-10"
                          style="background: #dde4ff !important; height: 34px !important; width: 34px !important; font-size: 30px !important; margin-bottom: 9px;"
                          v-if="getProperty(selectedDmv, 'partially_dismantled') == false">
                      </div>
                    </div>
                  </div>
                  <div style="display: inline-flex !important; width: 100%; margin-top: 120px;">
                    <div style="float: left !important;">
                      <div style="display: inline-flex !important; width: 100%; margin-top: 45px;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 0 0 65px; width: 510px;">
                            {{ getMomentProperty(selectedDmv, 'report_signed_at', 'MM/DD/yyyy') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 45px;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 400px 0 0; width: 1210px;">
                            {{ getProperty(selectedDmv, 'firm_name') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 20px 0 105px; width: 320px;">
                            {{ getProperty(selectedDmv, 'dismantler_no') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 45px;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 75px 0 0px; width: 1210px;">
                            {{ getProperty(selectedDmv, 'dismantler_address') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 250px 0 105px; width: 320px;">
                            {{ getProperty(selectedDmv, 'dismantler_city') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 35px; width: 250px;">
                            {{ getProperty(selectedDmv, 'dismantler_zip_code') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 250px;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin-left: 615px; width: 810px;">
                            {{ getProperty(selectedDmv, 'vin') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 145px;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; width: 290px; margin-left: -8px;">
                            {{ getProperty(selectedDmv, 'make') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 20px 0 25px; width: 270px;">
                            {{ getProperty(selectedDmv, 'year_model') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 10px; width: 260px;">
                            {{ getProperty(selectedDmv, 'license_no') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 20px 0 30px; width: 170px;">
                            {{ getProperty(selectedDmv, 'state_of') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 10px; width: 350px;">
                            {{ getMomentProperty(selectedDmv, 'period_ends_at', 'MM/DD/yyyy') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 45px;">
                        <div style="float: left !important; margin-left: 1470px;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 35px; width: 200px;">
                            {{ getMomentProperty(selectedDmv, 'possession_acquired_at', 'MM') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 35px; width: 200px;">
                            {{ getMomentProperty(selectedDmv, 'possession_acquired_at', 'DD') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 35px; width: 200px;">
                            {{ getMomentProperty(selectedDmv, 'possession_acquired_at', 'yyyy') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 45px;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 35px 0 0px; width: 1070px;">
                            {{ getProperty(selectedDmv, 'sellers_name') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 100px 0 0; width: 620px;">
                            {{ getProperty(selectedDmv, 'address') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 35px; width: 340px;">
                            {{ getProperty(selectedDmv, 'city') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 145px;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 0 0 65px; width: 510px;">
                            {{ getProperty(selectedDmv, 'acquisition_was_signed_at') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 45px;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 10px 0 0; width: 1060px;">
                            {{ getProperty(selectedDmv, 'firm_name') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 20px 0 35px; width: 320px;">
                            {{ getProperty(selectedDmv, 'dismantler_no') }}
                          </span>
                        </div>
                      </div>
                      <div style="display: inline-flex !important; width: 100%; margin-top: 45px;">
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 65px 0 0px; width: 1140px;">
                            {{ getProperty(selectedDmv, 'dismantler_address') }}
                          </span>
                        </div>
                        <div style="float: left !important;">
                          <span style="background: #dde4ff !important; display: flex; justify-content: center; align-items: center; height: 55px !important; font-size: 30px !important; margin: 0 250px 0 105px; width: 320px;">
                            {{ getProperty(selectedDmv, 'dismantler_zip_code') }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="green darken-4 green-gradient white--text text-capitalize"
                  :loading="loading"
                  @click="printDiv('dmv-main')">
                  {{ $t("dmvProcessing.YesPrintConfirmation") }}
                </v-btn>
                <v-btn
                  class="red darken-4 red-gradient white--text text-capitalize"
                  @click="confirmation = false"
                >
                  {{ $t("dmvProcessing.NoPrintConfirmation") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-responsive>
      </v-col>
    </v-row>
    <v-row class="no-gutters elevation-0" v-if="createMode">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <label>
                <span>
                  <v-icon
                    size="25"
                    class="white--text text--darken-4 mr-4"
                    @click="createMode = false"
                  >
                    mdi-arrow-left-bold
                  </v-icon>
                  {{
                    processingType !== "inside_redline_inventory"
                      ? $t("dmvProcessing.outsideRedlineInventory")
                      : $t("dmvProcessing.insideRedlineInventory")
                  }}
                </span>
              </label>
            </v-card-title>
            <v-card-text
              class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"
            >
              <div class="pa-10">
                <v-form>
                  <v-container>
                    <v-form class="mr-3">
                      <v-row>
                        <strong class="dmv-section-heading">
                          {{ $t("dmvProcessing.titleOfSectionOne") }}
                        </strong>
                      </v-row>
                      <v-row>

                        <v-col cols="12" md="8">
                          <v-menu
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            v-model="datePicker1"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                v-model="periodEndsAt"
                                hide-details="auto"
                                :label="$t('dmvProcessing.periodEndsAt')"
                                :error-messages="periodEndsAtErrors"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              color="green darken-4"
                              v-model="dmv.period_ends_at"
                              @input="datePicker1 = false"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            disabled
                            hide-details="auto"
                            v-model="dmv.state_of"
                            :label="$t('dmvProcessing.stateOf')"
                            :placeholder="$t('dmvProcessing.stateOf')"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            hide-details="auto"
                            v-model="dmv.license_no"
                            :label="$t('dmvProcessing.licenseNo')"
                            :placeholder="$t('dmvProcessing.licenseNo')"
                            :error-messages="licenseNoErrors"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            outlined
                            maxlength="17"
                            hide-details="auto"
                            :label="$t('dmvProcessing.vehicleIdentificationNo')"
                            :placeholder="$t('dmvProcessing.vehicleIdentificationNo')"
                            v-model="dmv.vin"
                            :loading="vinLoading"
                            @input="
                              getVinSearchData(dmv.vin);
                              $v.dmv.vin.$touch();
                              vinError = [];
                            "
                            @blur="$v.dmv.vin.$touch()"
                            :error-messages="vinErrors"
                          ></v-text-field>
                          <v-progress-linear
                            v-if="vinLoading"
                            indeterminate
                            color="green"
                          ></v-progress-linear>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-autocomplete
                            outlined
                            height="55"
                            :items="makeOptions"
                            v-model="dmv.make"
                            :attach="true"
                            :label="$t('dmvProcessing.make')"
                            :error-messages="makeErrors"
                            clearable
                            dense
                            chips
                            hide-details
                            @change="changeMakeOption"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            hide-details="auto"
                            v-model="dmv.body_type"
                            :label="$t('dmvProcessing.bodyType')"
                            :placeholder="$t('dmvProcessing.bodyType')"
                            :error-messages="bodyTypeErrors"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-autocomplete
                            outlined
                            height="55"
                            :items="yearList"
                            v-model="dmv.year_model"
                            :attach="true"
                            :label="$t('dmvProcessing.yearModel')"
                            :error-messages="yearModelErrors"
                            clearable
                            dense
                            chips
                            hide-details
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-menu
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            v-model="datePicker3"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                v-model="purchasedAt"
                                hide-details="auto"
                                :label="$t('dmvProcessing.wasPurchasedOn')"
                                :error-messages="wasPurchasedOnErrors"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              color="green darken-4"
                              v-model="dmv.purchased_at"
                              @input="datePicker3 = false"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-menu
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            v-model="datePicker2"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                v-model="possessionAcquiredAt"
                                hide-details="auto"
                                :label="$t('dmvProcessing.possessionAcquiredAt')"
                                :error-messages="possessionAcquiredAtErrors"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              color="green darken-4"
                              v-model="dmv.possession_acquired_at"
                              @input="datePicker2 = false"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <strong class="dmv-section-heading">
                          {{ $t("dmvProcessing.titleOfSectionFive") }}
                        </strong>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            hide-details="auto"
                            v-model="dmv.sellers_name"
                            :label="$t('dmvProcessing.fromSeller')"
                            :placeholder="$t('dmvProcessing.fromSeller')"
                            :error-messages="sellersNameErrors"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <GooglePlace
                            id="address"
                            :isRequired="true"
                            label="dmvProcessing.address"
                            validationMsg="dmvProcessing.validations.addressIsRequired"
                            :address="dmv.address"
                            @onAddressSelect="addressHandler"
                          />
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            hide-details="auto"
                            v-model="dmv.apt_no"
                            :label="$t('dmvProcessing.aptNo')"
                            :placeholder="$t('dmvProcessing.aptNo')"
                            :error-messages="aptNoErrors"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            hide-details="auto"
                            v-model="dmv.city"
                            :label="$t('dmvProcessing.city')"
                            :placeholder="$t('dmvProcessing.city')"
                            :error-messages="cityErrors"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            hide-details="auto"
                            v-model="dmv.state"
                            :label="$t('dmvProcessing.state')"
                            :placeholder="$t('dmvProcessing.state')"
                            :error-messages="stateErrors"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            hide-details="auto"
                            v-model="dmv.zip_code"
                            :label="$t('dmvProcessing.zipCode')"
                            :placeholder="$t('dmvProcessing.zipCode')"
                            :error-messages="zipCodeErrors"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <strong class="dmv-section-heading">
                          {{ $t("dmvProcessing.titleOfSectionTwo") }}
                        </strong>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-checkbox
                            v-model="dmv.certificate_of_title"
                            :label="$t('dmvProcessing.certificateOfTitle')"
                            color="green darken-3"
                            :value="true"
                            class="dmv-checkbox"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox
                            v-model="dmv.registration_card"
                            :label="$t('dmvProcessing.registrationCard')"
                            color="green darken-3"
                            :value="true"
                            class="dmv-checkbox"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox
                            v-model="dmv.validation"
                            :label="$t('dmvProcessing.validation')"
                            color="green darken-3"
                            :value="true"
                            class="dmv-checkbox"
                            hide-details
                          ></v-checkbox>
                          <v-row>
                            <v-col>
                              <v-checkbox
                                v-model="dmv.license_plate"
                                :label="$t('dmvProcessing.licensePlates')"
                                color="green darken-3"
                                :value="true"
                                class="dmv-checkbox"
                                hide-details
                              ></v-checkbox>
                            </v-col>
                            <v-col>
                              <v-radio-group
                                class="dmv-checkbox"
                                v-model="dmv.license_plates"
                                row
                                hide-details
                              >
                                <v-radio
                                  color="green darken-3"
                                  :label="$t('dmvProcessing.one')"
                                  :value=1
                                  hide-details
                                ></v-radio>
                                <v-radio
                                  color="green darken-3"
                                  :label="$t('dmvProcessing.two')"
                                  :value=2
                                  hide-details
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                          <v-checkbox
                            v-model="dmv.lien_sale_documents"
                            :label="$t('dmvProcessing.lienSaleDocuments')"
                            color="green darken-3"
                            :value="true"
                            class="dmv-checkbox"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox
                            v-model="dmv.salvage_certificate"
                            :label="$t('dmvProcessing.salvageCertificate')"
                            color="green darken-3"
                            :value="true"
                            class="dmv-checkbox"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox
                            v-model="dmv.non_repairable_vehicle_certificate"
                            :label="
                              $t(
                                'dmvProcessing.nonRepairableVehicleCertificate'
                              )
                            "
                            color="green darken-3"
                            :value="true"
                            class="dmv-checkbox"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox
                            v-model="dmv.bill_of_sale"
                            :label="$t('dmvProcessing.billOfSale')"
                            color="green darken-3"
                            :value="true"
                            class="dmv-checkbox"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <strong class="dmv-section-heading">
                          {{ $t("dmvProcessing.titleOfSectionThree") }}
                        </strong>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-checkbox
                            v-model="dmv.complete_vehicle"
                            :label="$t('dmvProcessing.completeVehicle')"
                            color="green darken-3"
                            :value="true"
                            class="dmv-checkbox"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox
                            v-model="dmv.frame_only"
                            :label="$t('dmvProcessing.frameOnly')"
                            color="green darken-3"
                            :value="true"
                            class="dmv-checkbox"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox
                            v-model="dmv.body_only"
                            :label="$t('dmvProcessing.bodyOnly')"
                            color="green darken-3"
                            :value="true"
                            class="dmv-checkbox"
                            hide-details
                          ></v-checkbox>
                          <v-checkbox
                            v-model="dmv.partially_dismantled"
                            :label="$t('dmvProcessing.partiallyDismantled')"
                            color="green darken-3"
                            :value="true"
                            class="dmv-checkbox"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <strong class="dmv-section-heading">
                          {{ $t("dmvProcessing.titleOfSectionFour") }}
                        </strong>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="12">
                          <v-checkbox
                            v-model="dmv.removed_license_plate"
                            :label="$t('dmvProcessing.removedLicensePlate')"
                            color="green darken-3"
                            :value="true"
                            class="dmv-checkbox"
                            hide-details
                          ></v-checkbox>
                          <v-row>
                            <v-col cols="12" md="8" style="margin-top: 8px !important;">
                              <label class="v-label theme--light" style="left: 0px; right: auto; position: relative;">
                                {{ $t("dmvProcessing.numberOfPlatesRemoved") }}
                              </label>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-radio-group
                                class="dmv-checkbox"
                                v-model="dmv.number_of_plates_removed"
                                row
                                hide-details
                              >
                                <v-radio
                                  color="green darken-3"
                                  :label="$t('dmvProcessing.one')"
                                  :value=1
                                  hide-details
                                ></v-radio>
                                <v-radio
                                  color="green darken-3"
                                  :label="$t('dmvProcessing.two')"
                                  :value=2
                                  hide-details
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                          <v-checkbox
                            v-model="dmv.possession_without_license_plates"
                            :label="
                              $t('dmvProcessing.possessionWithoutLicensePlates')
                            "
                            color="green darken-3"
                            :value="true"
                            class="dmv-checkbox"
                            hide-details
                          ></v-checkbox>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-menu
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            v-model="datePicker4"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                v-model="reportSignedAt"
                                hide-details="auto"
                                :label="$t('dmvProcessing.reportSignedAt')"
                                :error-messages="reportSignedAtErrors"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              color="green darken-4"
                              v-model="dmv.report_signed_at"
                              @input="datePicker4 = false"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" md="8">
                          <v-text-field
                            hide-details="auto"
                            v-model="dmv.firm_name"
                            :label="$t('dmvProcessing.firmName')"
                            :placeholder="$t('dmvProcessing.firmName')"
                            :error-messages="firmNameErrors"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            hide-details="auto"
                            v-model="dmv.dismantler_no"
                            :label="$t('dmvProcessing.dismantlerNo')"
                            :placeholder="$t('dmvProcessing.dismantlerNo')"
                            :error-messages="dismantlerNoErrors"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <AddressAutoComplete
                            :isRequired="true"
                            v-model="dmv.dismantler_address"
                            :options="locationOptions"
                            :placeholder="$t('dmvProcessing.dismantlerAddress')"
                            validationMsg="dmvProcessing.validations.dismantlerAddressIsRequired"
                            @place-changed="dismantlerAddressHandler">
                          </AddressAutoComplete>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            hide-details="auto"
                            v-model="dmv.dismantler_city"
                            :label="$t('dmvProcessing.dismantlerCity')"
                            :placeholder="$t('dmvProcessing.dismantlerCity')"
                            :error-messages="dismantlerCityErrors"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            hide-details="auto"
                            v-model="dmv.dismantler_zip_code"
                            :label="$t('dmvProcessing.dismantlerZipCode')"
                            :placeholder="$t('dmvProcessing.dismantlerCity')"
                            :error-messages="dismantlerZipCodeErrors"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-menu
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            v-model="datePicker5"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                v-model="acquisitionWasSignedAt"
                                hide-details="auto"
                                :label="$t('dmvProcessing.acquisitionWasSignedAt')"
                                :error-messages="acquisitionWasSignedAtErrors"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              color="green darken-4"
                              v-model="dmv.acquisition_was_signed_at"
                              @input="datePicker5 = false"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-btn
                            hide-details="auto"
                            large
                            color="green darkan-4 green-gradient white--text"
                            class="text-capitalize btn-submit"
                            @click="postDmv"
                            :loading="saving"
                            :disabled="saving"
                          >
                            {{ $t("dmvProcessing.save") }}
                            <template v-slot:loading>
                              <v-icon>mdi-loading</v-icon>
                            </template>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-form>
              </div>
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ITEMS_PER_PAGE} from "@/constants/common";
import {required, minLength} from "vuelidate/lib/validators";
import {get} from "lodash";
import moment from "moment";

export default {
  name: "DMVProcessing",
  components: {
    AddressAutoComplete: () => import("@/components/AddressAutoComplete"),
    GooglePlace: () => import("@/components/common/GooglePlace"),
  },
  data() {
    return {
      vinLoading: false,
      confirmation: false,
      valid: false,
      yearList: [],
      selectedDmv: null,
      locationOptions: {
        types: ["(regions)"],
        componentRestrictions: {country: "us"},
      },
      saving: false,
      has_dmv: false,
      errors: {
        periodEndsAt: null,
        license_no: null,
        vin: null,
        make: null,
        body_type: null,
        year_model: null,
        possession_acquired_at: null,
        purchased_at: null,
        sellers_name: null,
        address: null,
        apt_no: null,
        city: null,
        state: null,
        zip_code: null,
      },
      vinError: [],
      dmv: {
        id: null,
        car_profile_id: null,
        period_ends_at: "",
        state_of: "CA",
        license_no: "",
        vin: "",
        make: "",
        body_type: "",
        year_model: "",
        possession_acquired_at: "",
        purchased_at: "",
        sellers_name: "",
        address: "",
        apt_no: "",
        city: "",
        state: "",
        zip_code: "",
        certificate_of_title: false,
        registration_card: false,
        validation: false,
        license_plate: false,
        license_plates: null,
        lien_sale_documents: false,
        salvage_certificate: false,
        non_repairable_vehicle_certificate: false,
        bill_of_sale: false,
        complete_vehicle: false,
        frame_only: false,
        body_only: false,
        partially_dismantled: false,
        removed_license_plate: false,
        number_of_plates_removed: null,
        possession_without_license_plates: false,
        report_signed_at: "",
        firm_name: "",
        dismantler_no: "",
        dismantler_address: "",
        dismantler_city: "",
        dismantler_zip_code: "",
        acquisition_was_signed_at: "",
      },
      emailDialog: false,
      processingType: "",
      searchResult: false,
      loading: false,
      singleExpand: true,
      expanded: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 0,
      createMode: false,
      datePicker1: false,
      datePicker2: false,
      datePicker3: false,
      datePicker4: false,
      datePicker5: false,
    };
  },
  validations: {
    dmv: {
      period_ends_at: {required},
      state_of: {required},
      license_no: {required},
      vin: {required, minLength: minLength(17)},
      make: {required},
      body_type: {required},
      year_model: {required},
      possession_acquired_at: {required},
      purchased_at: {required},
      sellers_name: {required},
      address: {required},
      apt_no: {required},
      city: {required},
      state: {required},
      zip_code: {required},
      report_signed_at: {required},
      firm_name: {required},
      dismantler_no: {required},
      dismantler_address: {required},
      dismantler_city: {required},
      dismantler_zip_code: {required},
      acquisition_was_signed_at: {required},
    },
  },
  computed: {
    ...mapGetters({
      vinSearch: "bookLog/getVinSearchData",
      makeOptions: "bookLog/getMake",
      yearOptions: "bookLog/getYear",
      carProfiles: "dmv/getCarProfiles",
      metaInfo: "dmv/getCarProfileMetaInfo",
      dmvMetaInfo: "dmv/getDmvProcessingMetaInfo",
      dmvProcessing: "dmv/getDmvProcessing",
    }),
    headers() {
      return [
        {
          text: this.$t("dmvProcessing.invEntryDate"),
          align: "start",
          sortable: false,
          value: "created_at",
          width: "16%",
        },
        {
          text: this.$t("dmvProcessing.vin"),
          value: "vin",
          width: "26%",
        },
        {
          text: this.$t("dmvProcessing.makeModelYear"),
          value: "makeModelYear",
          width: "25%",
        },
        {
          text: this.$t("dmvProcessing.color"),
          value: "color",
          width: "10%",
        },
        {
          text: this.$t("dmvProcessing.dmvProcessed"),
          value: "dmvProcessed",
          width: "15%",
        },
        {
          text: this.$t("dmvProcessing.action"),
          value: "actions",
          width: "13%",
        },
      ];
    },
    dmvHeaders() {
      return [
        {
          text: this.$t("dmvProcessing.date"),
          align: "start",
          sortable: false,
          value: "created_at",
          width: "25%",
        },
        {
          text: this.$t("dmvProcessing.vin"),
          value: "vin",
          width: "25%",
        },
        {
          text: this.$t("dmvProcessing.makeYear"),
          value: "makeModelYear",
          width: "25%",
        },
        {
          text: this.$t("dmvProcessing.action"),
          value: "actions",
          width: "25%",
        },
      ];
    },
    periodEndsAt() {
      return this.dmv.period_ends_at
        ? moment(this.dmv.period_ends_at).format("MM/DD/YYYY")
        : "";
    },
    purchasedAt() {
      return this.dmv.purchased_at
        ? moment(this.dmv.purchased_at).format("MM/DD/YYYY")
        : "";
    },
    possessionAcquiredAt() {
      return this.dmv.possession_acquired_at
        ? moment(this.dmv.possession_acquired_at).format("MM/DD/YYYY")
        : "";
    },
    reportSignedAt() {
      return this.dmv.report_signed_at
        ? moment(this.dmv.report_signed_at).format("MM/DD/YYYY")
        : "";
    },
    acquisitionWasSignedAt() {
      return this.dmv.acquisition_was_signed_at
        ? moment(this.dmv.acquisition_was_signed_at).format("MM/DD/YYYY")
        : "";
    },
    periodEndsAtErrors() {
      const errors = [];

      if (!this.$v.dmv.period_ends_at.required) {
        errors.push(
          this.$t("dmvProcessing.validations.periodEndsAtIsRequired")
        );
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    licenseNoErrors() {
      const errors = [];

      if (!this.$v.dmv.license_no.required) {
        errors.push(this.$t("dmvProcessing.validations.licenseNoIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    vinErrors() {
      const vinErrors = [];
      if (!this.$v.dmv.vin.$dirty) return vinErrors;
      if (this.vinError && this.vinError.length) {
        return this.vinError;
      }
      !this.$v.dmv.vin.required &&
        vinErrors.push(this.$t("dmvProcessing.validations.vinIsRequired"));
      !this.$v.dmv.vin.minLength &&
        vinErrors.push(this.$t("dmvProcessing.validations.vinLength"));
      return vinErrors;
    },
    makeErrors() {
      const errors = [];

      if (!this.$v.dmv.make.required) {
        errors.push(this.$t("dmvProcessing.validations.makeIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    bodyTypeErrors() {
      const errors = [];

      if (!this.$v.dmv.body_type.required) {
        errors.push(this.$t("dmvProcessing.validations.bodyTypeIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    yearModelErrors() {
      const errors = [];

      if (!this.$v.dmv.year_model.required) {
        errors.push(this.$t("dmvProcessing.validations.yearModelIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    possessionAcquiredAtErrors() {
      const errors = [];

      if (!this.$v.dmv.possession_acquired_at.required) {
        errors.push(
          this.$t("dmvProcessing.validations.possessionAcquiredAtIsRequired")
        );
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    wasPurchasedOnErrors() {
      const errors = [];

      if (!this.$v.dmv.purchased_at.required) {
        errors.push(this.$t("dmvProcessing.validations.purchasedAtIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    sellersNameErrors() {
      const errors = [];

      if (!this.$v.dmv.sellers_name.required) {
        errors.push(this.$t("dmvProcessing.validations.sellersNameIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    addressErrors() {
      const errors = [];

      if (!this.$v.dmv.address.required) {
        errors.push(this.$t("dmvProcessing.validations.addressIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    aptNoErrors() {
      const errors = [];

      if (!this.$v.dmv.apt_no.required) {
        errors.push(this.$t("dmvProcessing.validations.aptNoIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    cityErrors() {
      const errors = [];

      if (!this.$v.dmv.city.required) {
        errors.push(this.$t("dmvProcessing.validations.cityIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    stateErrors() {
      const errors = [];

      if (!this.$v.dmv.state.required) {
        errors.push(this.$t("dmvProcessing.validations.stateIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    zipCodeErrors() {
      const errors = [];

      if (!this.$v.dmv.zip_code.required) {
        errors.push(this.$t("dmvProcessing.validations.zipCodeIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    reportSignedAtErrors() {
      const errors = [];

      if (!this.$v.dmv.report_signed_at.required) {
        errors.push(
          this.$t("dmvProcessing.validations.reportSignedAtIsRequired")
        );
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    firmNameErrors() {
      const errors = [];

      if (!this.$v.dmv.firm_name.required) {
        errors.push(
          this.$t("dmvProcessing.validations.firmNameIsRequired")
        );
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    dismantlerNoErrors() {
      const errors = [];

      if (!this.$v.dmv.dismantler_no.required) {
        errors.push(
          this.$t("dmvProcessing.validations.dismantlerNoIsRequired")
        );
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    dismantlerAddressErrors() {
      const errors = [];

      if (!this.$v.dmv.dismantler_address.required) {
        errors.push(
          this.$t("dmvProcessing.validations.dismantlerAddressIsRequired")
        );
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    dismantlerCityErrors() {
      const errors = [];

      if (!this.$v.dmv.dismantler_city.required) {
        errors.push(
          this.$t("dmvProcessing.validations.dismantlerCityIsRequired")
        );
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    dismantlerZipCodeErrors() {
      const errors = [];

      if (!this.$v.dmv.dismantler_zip_code.required) {
        errors.push(
          this.$t("dmvProcessing.validations.dismantlerZipCodeIsRequired")
        );
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    acquisitionWasSignedAtErrors() {
      const errors = [];

      if (!this.$v.dmv.acquisition_was_signed_at.required) {
        errors.push(
          this.$t("dmvProcessing.validations.acquisitionWasSignedAtIsRequired")
        );
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
  },
  mounted() {
    if(get(this.$route.query, "processingType")){
      this.processingType = get(this.$route.query, "processingType");
      this.initializeFields();
    }
    this.getMakeOptions();
  },
  methods: {
    ...mapActions({
      getVinSearch: "bookLog/vinSearch",
      getMake: "bookLog/getMake",
      getYear: "bookLog/getYear",
      saveDmv: "dmv/saveDmv",
      updateDmv: "dmv/updateDmv",
      getCarProfiles: "dmv/getCarProfiles",
      getDmvProcessing: "dmv/getDmvProcessing",
    }),

    async getVinSearchData(data) {
      try {
        if (data.length === 17) {
          this.vinLoading = true;
          await this.getVinSearch({ vin: data });
          this.dmv.make = this.vinSearch.make;
          this.dmv.year_model = this.vinSearch.year;
        } else {
          this.dmv.make = "";
          this.dmv.year_model = "";
        }
      } catch ({ message }) {
        this.vinError = message;
      } finally {
        this.vinLoading = false;
      }
    },

    async initializeFields() {
      await this.getDMV();
      await this.fetchDmv();
      this.$router.push({ name: "DMVProcessing", query: {processingType: this.processingType} });
    },

    async getMakeOptions() {
      try {
        await this.getMake();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    async setYear() {
      this.yearList = [];
      try {
        await this.getYear({
          make: this.dmv.make,
        });
        this.yearList = await [...this.yearOptions];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    async changeMakeOption() {
      this.dmv.year_model = null;
      await this.setYear();
    },

    async postDmv() {
      try {
        this.saving = true;
        if (this.has_dmv) {
          await this.updateDmv(this.dmv);
        } else {
          await this.saveDmv(this.dmv);
        }

        this.createMode = false;
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.saving = false;
      }
    },

    async fetchDmv(){
      try {
        this.loading = true;
        await this.getDmvProcessing({
          from_inside_inventory: this.processingType === "inside_redline_inventory" ? true : false,
          page: this.page,
          per_page: ITEMS_PER_PAGE,
        });
        this.searchResult = true;

        if (this.dmvMetaInfo) {
          this.itemsPerPage = parseInt(this.dmvMetaInfo.per_page);
          this.pageCount = this.dmvMetaInfo.last_page;
        } else {
          this.itemsPerPage = this.dmvProcessing.length;
          this.pageCount = 1;
        }

        await this.setYear();
      } catch ({ message }) {
        this.errors.partName = message.part_name;
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
    async getDMV() {
      try {
        this.loading = true;
        await this.getCarProfiles({
          page: this.page,
          per_page: ITEMS_PER_PAGE,
        });
        this.searchResult = true;

        if (this.metaInfo) {
          this.itemsPerPage = parseInt(this.metaInfo.per_page);
          this.pageCount = this.metaInfo.last_page;
        } else {
          this.itemsPerPage = this.carProfiles.length;
          this.pageCount = 1;
        }

        await this.setYear();
      } catch ({ message }) {
        this.errors.partName = message.part_name;
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    async setOutsideDmv(dmv) {
      this.has_dmv = true;
      this.setInitialData();
      this.dmv = dmv;

      await this.setYear();

      this.dmv.car_profile_id = null;
      this.createMode = true;
    },

    async setDmv(carProfile) {
      if (carProfile.inventory_dmv_processing) {
        this.has_dmv = true;
        this.setInitialData();
        this.dmv = carProfile.inventory_dmv_processing;
      } else {
        this.setInitialData();
        this.has_dmv = false;
        this.dmv.license_no = carProfile.license_plate;
        this.dmv.vin = carProfile.vin;
        this.dmv.make = carProfile.make;
        this.dmv.body_type = carProfile.body_type;
        this.dmv.year_model = carProfile.year;
        if (carProfile.user && get(carProfile.user, "user_detail")) {
          this.dmv.address = carProfile.user.user_detail.address;
          this.dmv.city = carProfile.user.user_detail.city;
          this.dmv.state = carProfile.user.user_detail.state;
          this.dmv.zip_code = carProfile.user.user_detail.zip_code;
        }
      }

      await this.setYear();

      this.dmv.car_profile_id = carProfile.id;
      this.createMode = true;
    },

    openConfirmModal(dmv) {
      this.selectedDmv = dmv;
      this.dmv = dmv;
      this.confirmation = true;
    },

    setInitialData() {
      this.dmv = {
        car_profile_id: null,
        period_ends_at: "",
        state_of: "CA",
        license_no: "",
        vin: "",
        make: "",
        body_type: "",
        year_model: "",
        possession_acquired_at: "",
        purchased_at: "",
        sellers_name: "",
        address: "",
        apt_no: "",
        city: "",
        state: "",
        zip_code: "",
        certificate_of_title: false,
        registration_card: false,
        validation: false,
        license_plate: false,
        license_plates: null,
        lien_sale_documents: false,
        salvage_certificate: false,
        non_repairable_vehicle_certificate: false,
        bill_of_sale: false,
        complete_vehicle: false,
        frame_only: false,
        body_only: false,
        partially_dismantled: false,
        removed_license_plate: false,
        number_of_plates_removed: null,
        possession_without_license_plates: false,
        report_signed_at: "",
        firm_name: "",
        dismantler_no: "",
        dismantler_address: "",
        dismantler_city: "",
        dismantler_zip_code: "",
        acquisition_was_signed_at: "",
      };
    },

    getProperty(key, name) {
      return get(key, name);
    },

    getMomentProperty(key, name, format) {
      return moment(get(key, name)).format(format);
    },

    async printDiv(divName) {
      this.confirmation = false;
      let divContents = document.getElementById(divName).innerHTML;
      let a = window.open("", "", 'height=600, width=850');

      a.document.write(divContents);
      a.document.close();
      a.print();

      if (a.onafterprint) {
        a.close();
      } else {
        setTimeout(() => {
          a.close();
        }, 100);
      }
    },

    addressHandler(payload) {
      this.dmv.address = payload.address;
      this.dmv.state = payload.state;
      this.dmv.zip_code = payload.zip;
      this.dmv.city = payload.city;
      this.validAddress = payload.valid;
    },

    back(){
      this.searchResult = false;
      this.processingType = "";
      this.$router.push({ name: "DMVProcessing" });
    },

    dismantlerAddressHandler(result) {
      this.dmv.dismantler_city = "";
      this.dmv.dismantler_zip_code = "";

      //start loop to get state from zip
      for (let component in result['address_components']) {
        for (let i in result['address_components'][component]['types']) {
          if (result['address_components'][component]['types'][i] == "administrative_area_level_1") {
            this.dmv.dismantler_city = result['address_components'][1]['long_name'];
          }
          if (result['address_components'][component]['types'][i] == "postal_code") {
            this.dmv.dismantler_zip_code = result['address_components'][component]['long_name'];
          }
        }
      }

      this.dmv.dismantler_address = result.formatted_address;
    },
  },
};
</script>

<style scoped>
.v-dialog__content {
  position: absolute;
}
</style>
